
html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #119dfc;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scroll-margin-top: 100px;
  /* position: relative; */
}

@font-face {
  font-family: "Futura";
  src: url("/Futura_Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Futura Condensed Bold";
  src: url("/Futura_Condensed_Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Futura Bold";
  src: url("/Futura_Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Violation";
  src: url("/Violation.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("/Roboto-Bold.ttf");
  font-display: swap;
}
